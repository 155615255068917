import { DetailedReportResponse } from '../../../@types/jtbd';
import { Job } from '../../../@types/report';

const getJobKeys = (jobs: Job[], prefix: string = ''): string[] => {
    return jobs.flatMap((job, index) => {
        const jobPrefix = prefix ? `${prefix}.${index + 1}` : `${index + 1}`;
        const keys = [`job-${jobPrefix}`];
        if (job.examples && job.examples.length > 0) {
            keys.push(`job-${jobPrefix}-examples`);
        }
        if (job.sub_jobs && job.sub_jobs.length > 0) {
            keys.push(...getJobKeys(job.sub_jobs, jobPrefix));
        }
        return keys;
    });
};

export const getAllKeys = (report: DetailedReportResponse): string[] => {
    const keys: string[] = [];
    if (report.profile) {
        keys.push(...report.profile.goals.map((_, i) => `goals-${i}`));
        keys.push(...report.profile.motivations.map((_, i) => `motivations-${i}`));
        keys.push(...report.profile.pain_points.map((_, i) => `pain-points-${i}`));
        keys.push(...report.profile.emotions.map((_, i) => `emotions-${i}`));
        keys.push(...report.profile.tools.map((_, i) => `tools-${i}`));
    }
    if (report.insights) {
        keys.push(...report.insights.key_insights.map((_, i) => `key-insights-${i}`));
        keys.push(...report.insights.barriers.map((_, i: number) => `barriers-${i}`));
        keys.push(...report.insights.engagements.map((_, i: number) => `engagements-${i}`));
        keys.push(...report.insights.expectations.map((_, i: number) => `expectations-${i}`));
    }
    if (report.jtbd) {
        keys.push(...report.jtbd.solutions.map((_, i) => `jtbd_solutions-${i}`));
        keys.push(...report.jtbd.outcomes.map((_, i) => `jtbd_outcomes-${i}`));
        keys.push(...getJobKeys(report.jtbd.identified_jobs));
    }
    if (report.recommendations) {
        keys.push(...report.recommendations.actionable_steps.map((_, i) => `actionable-steps-${i}`));
        keys.push(...report.recommendations.product_improvements.map((_, i) => `product-improvements-${i}`));
        keys.push(...report.recommendations.service_enhancements.map((_, i) => `service-enhancements-${i}`));
    }
    return keys;
};
