import { ExampledDescription, Insights } from 'src/@types/report';

export class InsightsFormatter {
    private formatExampledDescription(exampledDesc: ExampledDescription): string {
        const examplesText = exampledDesc.dialog_examples.map(ex => `    - \`${ex.id}: ${ex.text}\``).join('\n');
        const examplesSection = examplesText ? `\n - \`sources:\`\n${examplesText}` : '';
        return `- **${exampledDesc.title}:** ${exampledDesc.text}${examplesSection}`;
    }

    public formatInsights(insights: Insights): string {
        const sections: string[] = [
            '### Insights',
            '\n#### Key Insights',
            insights.key_insights.map(this.formatExampledDescription).join('\n'),
            '\n#### Barriers',
            insights.barriers.map(this.formatExampledDescription).join('\n'),
            '\n#### Engagement',
            insights.engagements.map(this.formatExampledDescription).join('\n'),
            '\n#### Expectations',
            insights.expectations.map(this.formatExampledDescription).join('\n'),
        ];

        return sections.join('\n');
    }
}
