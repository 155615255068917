import React from 'react';
import { Outlet } from 'react-router-dom';

import { Layout } from 'antd';

import AseedMenu from 'src/components/Menu/Menu';

import { ReportNotificationProvider } from './ReportNotificationContext';

import styles from './MainLayout.module.scss';

export const MainLayout: React.FC = () => {
    return (
        <Layout>
            <div className={mainLayout}>
                <ReportNotificationProvider>
                    <main className={contentWrapper}>
                        <Outlet />
                    </main>
                    <aside className={mainSidebar}>
                        <AseedMenu />
                    </aside>
                </ReportNotificationProvider>
            </div>
        </Layout>
    );
};

const { mainSidebar, mainLayout, contentWrapper } = styles;
