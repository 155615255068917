import React from 'react';

import { Flex, Space } from 'antd';

import { AnchorLinkParams, AnchorLinks } from '../../../../AnchorLinks/AnchorLinks';
import { ReportDescriptionSection } from '../../../../renders/RenderDescriptionSection';
import { IdentifiedJobs } from '../../../../renders/RenderIdentifiedJobs';
import { JTBD } from 'src/@types/report';

interface ReportJTBDProps {
    jtbd: JTBD;
}

export const ReportJTBD: React.FC<ReportJTBDProps> = ({ jtbd }) => {
    const links = getAnchorLinks(jtbd);

    return (
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <AnchorLinks links={links} />
            <Flex gap={12} vertical style={{ padding: '0 90px' }}>
                <div
                    id="dentified-jobs"
                    style={{
                        borderRadius: '16px',
                        padding: '16px',
                        margin: '0',
                    }}
                >
                    <Space direction="vertical" size={12} style={{ width: '100%' }}>
                        <h2
                            style={{
                                transition: 'font-size 0.3s ease',
                                fontSize: '24px',
                                lineHeight: '24px',
                                margin: 0,
                                fontVariant: 'normal',
                                fontWeight: 'bold',
                            }}
                        >
                            Identified Jobs
                        </h2>
                        <IdentifiedJobs jobs={jtbd.identified_jobs} />
                    </Space>
                </div>
                <ReportDescriptionSection id="jtbd_solutions" title="Solutions" items={jtbd.solutions} iconSymbol="􀦂" />
                <ReportDescriptionSection id="jtbd_outcomes" title="Outcomes" items={jtbd.outcomes} iconSymbol="􀛯" />
            </Flex>
        </Space>
    );
};

const getAnchorLinks = (jtbd: JTBD): AnchorLinkParams[] => {
    return [
        {
            href: '#identified-jobs',
            title: 'Identified Jobs',
            iconSymbol: '􀷔',
            count: jtbd.identified_jobs.length,
        },
        {
            href: '#jtbd_solutions',
            title: 'Solutions',
            iconSymbol: '􀦂',
            count: jtbd.solutions.length,
        },
        {
            href: '#jtbd_outcomes',
            title: 'Outcomes',
            iconSymbol: '􀛯',
            count: jtbd.outcomes.length,
        },
    ];
};
