export enum Language {
    English = 'English',
    Russian = 'Russian',
    Spanish = 'Spanish',
    French = 'French',
    German = 'German',
}

export enum ReportStatus {
    Uploaded = 'Uploaded',
    InProgress = 'InProgress',
    InQueue = 'InQueue',
    Finished = 'Finished',
    Error = 'Error',
}
