import { useState } from 'react';

import { Button, Tabs } from 'antd';

import { getAllKeys } from 'src/components/Report/helpers/JobKeys';

import { useIsSetupContextAvailable } from '../ReportSetupContext';
import { useReportTabsContext } from './ReportTabsContext';
import { ReportInsights, ReportJTBD, ReportProfile, ReportRecommendations, ReportSetup, ReportTranscript } from './Tabs';
import { EmptyTabContent } from './Tabs/EmptyTabContent';
import { ErrorTabContent } from './Tabs/ErrorTabContent';
import { ReportStatus } from 'src/@types/enums';
import { DetailedReportResponse } from 'src/@types/jtbd';

interface ReportTabsProps {
    report: DetailedReportResponse;
}

const ReportTabs: React.FC<ReportTabsProps> = ({ report }) => {
    const { expandedKeysState } = useReportTabsContext();
    const isSetupContextAvailable = useIsSetupContextAvailable();

    const [activeTab, setActiveTab] = useState<string>(getDefaultActiveTab(report));

    const [expandedKeys, setExpandedKeys] = expandedKeysState;

    const toggleExpandAll = () => {
        const allKeys = getAllKeys(report);
        setExpandedKeys(expandedKeys.length > 0 ? [] : allKeys);
    };

    const handleTabChange = (activeKey: string) => {
        setActiveTab(activeKey);
    };

    const showExpandAllButton = !['setup', 'transcript'].includes(activeTab);
    const isSetupAvailable = isSetupContextAvailable && report.setup; // TODO

    const items = [
        isSetupAvailable && {
            key: 'setup',
            label: '􀍟 Setup',
            children: <ReportSetup />,
        },
        {
            key: 'transcript',
            label: '􀌮 Transcript',
            children:
                report.transcripts && report.transcripts.length > 0 ? (
                    <ReportTranscript transcripts={report.transcripts} />
                ) : (
                    <EmptyTabContent description="Transcript" />
                ),
        },
        {
            key: 'profile',
            label: '􀉮 Profile',
            children: report.profile ? <ReportProfile profile={report.profile} /> : <EmptyTabContent description="Profile" />,
        },
        {
            key: 'insights',
            label: '􀫸 Insights',
            children: report.insights ? <ReportInsights insights={report.insights} /> : <EmptyTabContent description="Insights" />,
        },
        {
            key: 'jtbd',
            label: '􀵫 JTBD',
            children: report.jtbd ? <ReportJTBD jtbd={report.jtbd} /> : <EmptyTabContent description="JTBD" />,
        },
        {
            key: 'recommendations',
            label: '􂨪 Recommendations',
            children: report.recommendations ? (
                <ReportRecommendations recommendations={report.recommendations} />
            ) : (
                <EmptyTabContent description="Recommendations" />
            ),
        },
    ].filter(Boolean);

    if (report.status === ReportStatus.Error) {
        return <ErrorTabContent />;
    }

    return (
        <Tabs
            type="card"
            defaultActiveKey={activeTab}
            onChange={handleTabChange}
            items={items}
            tabBarExtraContent={
                showExpandAllButton &&
                report.status === ReportStatus.Finished && (
                    <Button onClick={toggleExpandAll}>{expandedKeys.length > 0 ? 'Collapse all' : 'Expand all'}</Button>
                )
            }
        />
    );
};

export default ReportTabs;

const getDefaultActiveTab = (report: DetailedReportResponse): string => {
    if (report.profile) return 'profile';
    if (report.transcripts && report.transcripts.length > 0) return 'transcript';
    return 'setup';
};
