import { useEffect, useState } from 'react';

import { Button, Flex, Input, Select, Tooltip } from 'antd';

import { AseedText } from 'src/components/AseedTypography/AseedText/AseedText';
import { AseedTitle } from 'src/components/AseedTypography/AseedTitle/AseedTitle';
import { useReportSetupContext } from 'src/components/Report/ReportWidget/ReportSetupContext';

import { TEXT_AREA_PLACEHOLDER } from '../../../../helpers/Placeholders';
import { AUTOMATIC_DETECTION, SAME_AS_TRANSCRIPT, createLanguageOptions, createParticipantOptions } from '../../../../helpers/SetupOptions';
import { Language } from 'src/services/enums';

import styles from './ReportSetup.module.scss';

const { respondentWrapper, deleteBtn } = styles;

const MAX_PARTICIPANTS = 6;
const MAX_RESPONDENTS = 3;

const RespondentInput: React.FC<{
    maxRespondents: number;
    respondents: string[];
    onRespondentsChange: (respondents: string[]) => void;
}> = ({ maxRespondents, respondents, onRespondentsChange }) => {
    const handleInputChange = (index: number, value: string) => {
        const newRespondents = [...respondents];
        newRespondents[index] = value;
        onRespondentsChange(newRespondents);
    };

    const addRespondent = () => {
        const newRespondents = [...respondents, ''];
        onRespondentsChange(newRespondents);
    };

    const removeRespondent = (index: number) => {
        const newRespondents = respondents.filter((_, i) => i !== index);
        onRespondentsChange(newRespondents);
    };

    const currentParticipantCount = respondents.length < Math.min(maxRespondents - 1, MAX_RESPONDENTS);

    return (
        <Flex wrap="wrap" gap={12}>
            {respondents.map((respondent: string, index) => (
                <Flex key={index} className={respondentWrapper} vertical gap={9} style={{ width: 240 }}>
                    <Flex align="center" justify="space-between">
                        <AseedText styles={{ fontSize: 16 }}>{index === 0 ? 'Respondent Name' : `Respondent Name ${index + 1}`}</AseedText>
                        {respondents.length > 1 && (
                            <button className={deleteBtn} onClick={() => removeRespondent(index)}>
                                ⌫
                            </button>
                        )}
                    </Flex>
                    <Input
                        placeholder="Respondent"
                        value={respondent}
                        onChange={e => handleInputChange(index, e.target.value)}
                        maxLength={24}
                    />
                </Flex>
            ))}
            {currentParticipantCount && (
                <Flex
                    vertical
                    gap={9}
                    style={{
                        opacity: 0.5,
                        transition: 'opacity 0.3s',
                    }}
                    onMouseEnter={e => (e.currentTarget.style.opacity = '1')}
                    onMouseLeave={e => (e.currentTarget.style.opacity = '0.5')}
                >
                    <AseedText styles={{ fontSize: 16 }}>New Respondent Name</AseedText>

                    <Button
                        type="dashed"
                        style={{
                            width: 240,
                            height: 45,
                            borderRadius: 12,
                        }}
                        onClick={addRespondent}
                    >
                        Add Another Respondent
                    </Button>
                </Flex>
            )}
        </Flex>
    );
};

export const ReportSetup: React.FC = () => {
    const { reportState, setReportState, isWithInsightsState, isWithJTBDState, isWithProfileState, isWithRecommendationsState } =
        useReportSetupContext();

    const [isWithInsights] = isWithInsightsState;
    const [isWithJTBD] = isWithJTBDState;
    const [isWithProfile] = isWithProfileState;
    const [isWithRecommendations] = isWithRecommendationsState;

    const isInsightsReportEnabled = isWithInsights || isWithJTBD || isWithProfile || isWithRecommendations;

    const handleInterviewLanguageChange = (value: string) => {
        setReportState(prevState => ({
            ...prevState,
            language: value === AUTOMATIC_DETECTION ? null : (value as Language),
        }));
    };

    const handleReportLanguageChange = (value: string) => {
        setReportState(prevState => ({
            ...prevState,
            report_language: value === SAME_AS_TRANSCRIPT ? null : value,
        }));
    };

    const handleParticipantChange = (value: string) => {
        setReportState(prevState => ({
            ...prevState,
            participant_count: value === AUTOMATIC_DETECTION ? null : parseInt(value),
        }));
    };

    const handleReportGoalsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReportState(prevState => ({
            ...prevState,
            goals: e.target.value || null,
        }));
    };

    const handleRespondentsChange = (newRespondents: string[]) => {
        setReportState(prevState => ({
            ...prevState,
            respondent_known_names: newRespondents,
        }));
    };

    return (
        <Flex style={{ paddingTop: 30, width: '100%' }}>
            <Flex gap={48} vertical style={{ padding: '0 90px' }}>
                <Flex gap={12} vertical>
                    <Flex vertical gap={0}>
                        <AseedTitle>Transcription Setup</AseedTitle>
                        <AseedText description styles={{ fontSize: 15 }}>
                            For more accurate transcriptions, provide interview details. Optional, but it can make a difference.
                        </AseedText>
                    </Flex>
                    <Flex gap={12}>
                        <Flex vertical gap={9}>
                            <AseedText styles={{ fontSize: 16 }}>Interview Language</AseedText>
                            <Select
                                style={{ width: 240 }}
                                options={createLanguageOptions(AUTOMATIC_DETECTION)}
                                defaultValue={reportState.language || AUTOMATIC_DETECTION}
                                onChange={handleInterviewLanguageChange}
                            />
                        </Flex>
                        <Flex vertical gap={9}>
                            <AseedText styles={{ fontSize: 16 }}>Number of Participants</AseedText>
                            <Select
                                style={{ width: 240 }}
                                options={createParticipantOptions(MAX_PARTICIPANTS).filter(
                                    option =>
                                        option.value === AUTOMATIC_DETECTION ||
                                        parseInt(option.value) > reportState.respondent_known_names.length
                                )}
                                defaultValue={reportState.participant_count?.toString() || AUTOMATIC_DETECTION}
                                onChange={handleParticipantChange}
                            />
                        </Flex>
                    </Flex>
                    <RespondentInput
                        maxRespondents={reportState.participant_count ?? MAX_PARTICIPANTS}
                        respondents={reportState.respondent_known_names}
                        onRespondentsChange={handleRespondentsChange}
                    />
                </Flex>
                {isInsightsReportEnabled && (
                    <Flex gap={12} vertical>
                        <Flex vertical gap={0}>
                            <AseedTitle>Insight Report Setup</AseedTitle>
                            <AseedText description styles={{ fontSize: 15 }}>
                                Provide product details and research goals to enhance report accuracy.
                            </AseedText>
                        </Flex>
                        <Input.TextArea
                            placeholder={TEXT_AREA_PLACEHOLDER}
                            autoSize={{ minRows: 6, maxRows: 10 }}
                            style={{ marginTop: 8 }}
                            onChange={handleReportGoalsChange}
                            value={reportState.goals || ''}
                        />
                        <Flex vertical gap={9}>
                            <AseedText styles={{ fontSize: 16 }}>Report Language</AseedText>
                            <Tooltip title="Will be soon :)">
                                <Select
                                    disabled={true}
                                    style={{ width: 240 }}
                                    options={createLanguageOptions(SAME_AS_TRANSCRIPT)}
                                    defaultValue={reportState.report_language || SAME_AS_TRANSCRIPT}
                                    onChange={handleReportLanguageChange}
                                />
                            </Tooltip>
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};
