import React, { Dispatch, SetStateAction, useState } from 'react';

import { AuthModal, ModalPages } from 'src/components/AuthModal';

import HeaderLogo from 'src/assets/images/header/header-logo.svg';

import styles from './Header.module.scss';

const { headerBtn, headerLayout } = styles;

interface ModalContextType {
    setModalPage: React.Dispatch<React.SetStateAction<ModalPages | undefined>> | React.FC;
}

export const ModalContext = React.createContext<ModalContextType>({ setModalPage: () => {} });

interface AppHeaderProps {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const AppHeader: React.FC<AppHeaderProps> = ({ isModalOpen, setIsModalOpen }) => {
    const [modalPage, setModalPage] = useState<ModalPages>();

    const showSignInModal = () => {
        setModalPage(ModalPages.SignIn);
        setIsModalOpen(true);
    };

    const showSignUpModal = () => {
        setModalPage(ModalPages.SignUp);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setModalPage(ModalPages.SignIn);
    };

    return (
        <>
            <header className={headerLayout}>
                <img src={HeaderLogo} alt="" />
                <h1>Find Product-Market-Fit Fast</h1>
                <p>Analyze user interviews to uncover deep insights and build products users love</p>
                <button className={headerBtn} onClick={showSignInModal}>
                    {' '}
                    Sign In{' '}
                </button>
                <button className={headerBtn} onClick={showSignUpModal}>
                    {' '}
                    Sign Up{' '}
                </button>
            </header>
            <ModalContext.Provider value={{ setModalPage }}>
                <AuthModal isModalOpen={isModalOpen} onOk={handleOk} onCancel={handleCancel} page={modalPage} />
            </ModalContext.Provider>
        </>
    );
};
