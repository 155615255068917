import { Empty, Flex } from 'antd';

export const ErrorTabContent: React.FC = () => (
    <Flex
        justify="center"
        align="flex-start"
        style={{
            height: '100%',
            paddingTop: '20%',
        }}
    >
        <Empty description={`Something went wrong. We are working on resolving problem already and will contact you soon.`}></Empty>
    </Flex>
);
