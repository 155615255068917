import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Typography, theme } from 'antd';

import { AseedTitle } from 'src/components/AseedTypography/AseedTitle/AseedTitle';

import { DetailedReportResponse } from '../../../@types/jtbd';
import ReportExport from './ReportExport';
import { useIsSetupContextAvailable } from './ReportSetupContext';
import { useReportWidgetContext } from './ReportWidgetContext';
import { PATH } from 'src/constants/paths';

import styles from './ReportHeader.module.scss';

const { Title } = Typography;

const ReportHeader: React.FC<{ report: DetailedReportResponse }> = ({ report }) => {
    const {
        token: { colorTextSecondary },
    } = theme.useToken();

    const { updateName } = useReportWidgetContext();

    const [name, setName] = useState<string>(report.name);

    const onChangeName = (newName: string) => {
        if (!newName) return;
        setName(newName);
        updateName(newName);
    };

    const isNotDemo = useIsSetupContextAvailable();

    if (!isNotDemo) {
        return (
            <div className={contentLayout}>
                <Title className={titleEditor} level={3}>
                    {name}
                </Title>
                <AseedTitle>•</AseedTitle>
                <AseedTitle level={2} fontWeight={400}>
                    {report.setup?.respondent_known_names[0]}
                </AseedTitle>
                <Typography style={{ verticalAlign: 'bottom', color: colorTextSecondary, position: 'relative', top: 3 }}>
                    (20 Jul 2024)
                </Typography>
                <ReportExport className={exportBtn} report={report} />
            </div>
        );
    }

    return (
        <div className={contentLayout}>
            <Link className={link} to={PATH.WORKSPACE}>
                􀆉
            </Link>
            <Title
                editable={{
                    onChange: onChangeName,
                    triggerType: isNotDemo ? ['text'] : [],
                    enterIcon: null,
                }}
                className={titleEditor}
                level={3}
            >
                {name}
            </Title>
            <ReportExport className={exportBtn} report={report} />
        </div>
    );
};

export default ReportHeader;

const { contentLayout, link, titleEditor, exportBtn } = styles;
