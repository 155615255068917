import { Empty, Flex } from 'antd';

export const EmptyTabContent: React.FC<{ description: string }> = ({ description }) => (
    <Flex
        justify="center"
        align="flex-start"
        style={{
            height: '100%',
            paddingTop: '20%',
        }}
    >
        <Empty description={`${description} will be here`}></Empty>
    </Flex>
);
