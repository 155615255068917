import { DescriptionItems } from '../renders/RenderDescriptionSection';
import { ExampledDescription, GeneralProfileInfo, ScoredDescription, SolutionsDescription } from 'src/@types/report';

const objectToText = (items: ExampledDescription[] | SolutionsDescription[] | ScoredDescription[] | GeneralProfileInfo): string => {
    if (Array.isArray(items)) {
        return items
            .map(item => {
                if ('title' in item && 'description' in item) {
                    return `${item.title}\n${item.description}\n`;
                } else if ('name' in item && 'score' in item) {
                    return `${item.name}: ${item.score}\n`;
                } else if ('title' in item && 'text' in item) {
                    return `${item.title}\n${item.text}\n`;
                } else if ('title' in item && 'solutions' in item) {
                    return `${item.title}\n${item.solutions.map(sol => `  • ${sol.prefix_text ? sol.prefix_text + ': ' : ''}${sol.text}`).join('\n')}\n`;
                }
                return Object.entries(item)
                    .map(([key, value]) => `${key}: ${value}`)
                    .join('\n');
            })
            .join('\n');
    } else if (items) {
        return Object.entries(items)
            .map(([key, value]) => `${key}: ${value}`)
            .join('\n');
    } else {
        return '';
    }
};

const CopyDescriptionToBuffer = (title: string, items: DescriptionItems) => {
    const textToCopy = objectToText(items);
    navigator.clipboard.writeText(title + '\n' + textToCopy);
};

export default CopyDescriptionToBuffer;
