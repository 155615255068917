import React from 'react';

import { Flex, Space } from 'antd';

import { AnchorLinkParams, AnchorLinks } from '../../../../AnchorLinks/AnchorLinks';
import { ReportDescriptionSection } from '../../../../renders/RenderDescriptionSection';
import { Profile } from 'src/@types/report';

interface ReportProfileProps {
    profile: Profile;
}

export const ReportProfile: React.FC<ReportProfileProps> = ({ profile }) => {
    const links = getAnchorLinks(profile);
    const sections = getSections(profile);

    return (
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <AnchorLinks links={links} />
            <Flex gap={12} vertical style={{ padding: '0 90px' }}>
                {sections.map(({ id, title, items, iconSymbol }) => (
                    <ReportDescriptionSection key={id} id={id} title={title} items={items} iconSymbol={iconSymbol} />
                ))}
            </Flex>
        </Space>
    );
};

const getAnchorLinks = (profile: Profile): AnchorLinkParams[] => {
    return [
        {
            href: '#general-info',
            iconSymbol: '􀯧',
            title: 'General Info',
        },
        {
            href: '#goals',
            title: 'Goals',
            iconSymbol: '􀎫',
            count: profile.goals.length,
        },
        {
            href: '#motivations',
            title: 'Motivations',
            iconSymbol: '􀟖',
            count: profile.motivations.length,
        },
        {
            href: '#pain-points',
            title: 'Pain Points',
            iconSymbol: '􀋦',
            count: profile.pain_points.length,
        },
        {
            href: '#emotions',
            title: 'Emotions',
            iconSymbol: '􀊴',
            count: profile.emotions.length,
        },
        {
            href: '#tools',
            title: 'Tools',
            iconSymbol: '􀦂',
            count: profile.tools.length,
        },
    ];
};

const getSections = (profile: Profile) => {
    return [
        {
            id: 'general-info',
            title: 'General Info',
            items: profile.general_info,
            iconSymbol: '􀎫',
        },
        {
            id: 'goals',
            title: 'Goals',
            items: profile.goals,
            iconSymbol: '􀎫',
        },
        {
            id: 'motivations',
            title: 'Motivations',
            items: profile.motivations,
            iconSymbol: '􀟖',
        },
        {
            id: 'pain-points',
            title: 'Pain Points',
            items: profile.pain_points,
            iconSymbol: '􀋦',
        },
        {
            id: 'emotions',
            title: 'Emotions',
            items: profile.emotions,
            iconSymbol: '􀊴',
        },
        {
            id: 'tools',
            title: 'Tools',
            items: profile.tools,
            iconSymbol: '􀦂',
        },
    ];
};
