import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { DetailedReportResponse } from 'src/@types/jtbd';
import { useReportNotificationContext } from 'src/layouts/ReportNotificationContext';
import reportService from 'src/services/intro.service';
import jtbdService from 'src/services/jtbd.service';

type ReportWidgetContext = {
    isLoadingReport: boolean;
    report?: DetailedReportResponse;
    reportId?: string;
    reloadReport: () => void;
    updateName: (reportName: string) => void;
};

const ReportWidgetCtx = createContext<ReportWidgetContext>({
    isLoadingReport: false,
    reloadReport: () => {},
    updateName: () => {},
});

export const ReportWidgetProvider: React.FC<{ reportId: string; children: ReactNode }> = ({ reportId, children }) => {
    const { socketEvent } = useReportNotificationContext();
    const [report, setReportState] = useState<DetailedReportResponse>();
    const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);

    const fetchReport = useCallback(async () => {
        try {
            setIsLoadingReport(true);
            const reportData = await jtbdService.getDetailedReport(reportId);
            setReportState(reportData);
        } catch (error) {
            console.error('Не удалось получить подробный отчет', error);
        } finally {
            setIsLoadingReport(false);
        }
    }, [reportId]);

    const updateName = useCallback(
        async (reportName: string) => {
            try {
                await jtbdService.updateRecords({
                    change_names: [
                        {
                            id: reportId,
                            name: reportName,
                        },
                    ],
                    for_archive: [],
                    for_delete: [],
                });
            } catch (error) {
                console.error('Не удалось обновить имя отчета', error);
            }
        },
        [reportId]
    );

    useEffect(() => {
        fetchReport();
    }, []);

    useEffect(() => {
        if (!socketEvent?.data) {
            return;
        }
        if (JSON.parse(socketEvent?.data).id === reportId) {
            fetchReport();
        }
    }, [reportId, socketEvent]);

    const values = useMemo(
        () => ({
            reportId,
            report,
            isLoadingReport,
            updateName,
            reloadReport: fetchReport,
        }),
        [reportId, report, isLoadingReport, updateName, fetchReport]
    );

    return <ReportWidgetCtx.Provider value={values}>{children}</ReportWidgetCtx.Provider>;
};

export const ReportWidgetIntroProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [report, setReportState] = useState<DetailedReportResponse>();
    const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);

    const fetchReport = useCallback(async () => {
        try {
            setIsLoadingReport(true);
            const reportData = await reportService.getIntroReport();
            setReportState(reportData);
        } catch (error) {
            console.error('Не удалось получить подробный отчет', error);
        } finally {
            setIsLoadingReport(false);
        }
    }, []);

    useEffect(() => {
        fetchReport();
    }, []);

    const values = useMemo(
        () => ({
            report,
            isLoadingReport,
            reloadReport: fetchReport,
            updateName: () => {},
        }),
        [report, isLoadingReport, fetchReport]
    );

    return <ReportWidgetCtx.Provider value={values}>{children}</ReportWidgetCtx.Provider>;
};

export const useReportWidgetContext = (): ReportWidgetContext => {
    const context = useContext(ReportWidgetCtx);
    if (context === undefined) {
        throw new Error('useReportWidgetContext must be used within a ReportWidgetProvider');
    }
    return context;
};
