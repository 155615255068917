import React, { Dispatch, SetStateAction, useContext } from 'react';

import { Button, Divider, Flex, Form, FormProps, Input, theme } from 'antd';

import AuthService from '../../services/auth.service';
import { ModalContext } from '../Header/Header';
import { ModalPages } from './index';

type FieldType = {
    email: string;
};

interface ForgotPasswordProps {
    setEmail: Dispatch<SetStateAction<string>>;
}

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = errorInfo => {
    console.log('Failed:', errorInfo);
};

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({ setEmail }) => {
    const {
        token: { colorPrimary, margin, marginXL, marginXXL, marginXS, sizeXXL, paddingLG, fontSizeLG, colorWhite, colorError },
    } = theme.useToken();

    const [error, setError] = React.useState('');

    const { setModalPage } = useContext(ModalContext);

    const onFinish: FormProps<FieldType>['onFinish'] = ({ email }) => {
        AuthService.restore(email).then(r => {
            if (r.status === 'Error') {
                setError('Restore failed');
            } else {
                setModalPage(ModalPages.CheckEmail);
            }
        });
    };

    return (
        <Flex justify="center" wrap>
            <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="on"
                style={{ marginTop: marginXL, width: 330 }}
            >
                <Form.Item<FieldType>
                    name="email"
                    rules={[{ required: true, message: 'Please input your email' }]}
                    style={{ marginBottom: marginXXL }}
                >
                    <Input
                        size="large"
                        placeholder="Your email"
                        style={{
                            height: sizeXXL,
                            borderRadius: sizeXXL,
                            paddingLeft: paddingLG,
                            paddingRight: paddingLG,
                            fontSize: fontSizeLG + 2,
                            color: '#6A6A70',
                            backgroundColor: 'rgba(255, 255, 255, 0.4)',
                        }}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" size="large" htmlType="submit" style={{ borderRadius: sizeXXL, height: sizeXXL, width: '100%' }}>
                        Restore
                    </Button>
                </Form.Item>

                <Divider style={{ borderColor: colorWhite, marginTop: marginXXL, color: colorError }}>{error}</Divider>

                <Flex justify="center" style={{ fontSize: fontSizeLG + 2, marginBottom: margin }}>
                    <div style={{ color: '#6A6A70', marginRight: marginXS }}>Do you have an Account?</div>
                    <div style={{ color: colorPrimary, cursor: 'pointer' }} onClick={() => setModalPage(ModalPages.SignIn)}>
                        Sign In
                    </div>
                </Flex>
            </Form>
        </Flex>
    );
};
