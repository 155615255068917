import React from 'react';

import { Typography } from 'antd';

const { Title } = Typography;

interface AseedTitleProps {
    level?: 1 | 2 | 3 | 4 | 5;
    children: React.ReactNode;
    fontWeight?: 700 | 400;
    styles?: object;
}

export const AseedTitle: React.FC<AseedTitleProps> = ({ level = 2, fontWeight = 700, children, styles }) => {
    const fontSize = level === 2 ? 24 : undefined;

    return (
        <Title
            level={level}
            style={{
                fontFamily: 'SF Pro Display, sans-serif',
                transition: 'font-size 0.3s ease',
                margin: 0,
                fontVariant: 'normal',
                fontWeight,
                fontSize,
                lineHeight: '120%',
                ...styles,
            }}
        >
            {children}
        </Title>
    );
};
