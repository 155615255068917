import { ProfileResponse } from '../@types/profile';
import config from '../config';
import { concatUrl } from './helpers/url-helper';
import axios from 'axios';

const { ASEED_API_URL } = config;

class ProfileService {
    prefix = '/api/v1/profile';

    async getClientProfile(): Promise<ProfileResponse> {
        const url = new URL(concatUrl(ASEED_API_URL, `${this.prefix}/custdev`)).toString();
        const response = await axios.get(url);

        if (response.status === 200) {
            return response.data;
        }

        throw new Error('Failed to fetch client profile');
    }

    async claimTokens(): Promise<boolean> {
        const url = new URL(concatUrl(ASEED_API_URL, `${this.prefix}/claim-tokens`)).toString();

        try {
            const response = await axios.post(url);

            if (response.status === 204) {
                return true;
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 429) {
                    return false;
                }
            }
        }

        throw new Error('Failed to claim tokens');
    }

    async uploadAvatar(file: File): Promise<string> {
        if (!this.validateImageFile(file)) {
            throw new Error('Invalid file format or size');
        }

        const resizedFile = await this.resizeImage(file);

        const formData = new FormData();
        formData.append('file', resizedFile);

        const url = new URL(concatUrl(ASEED_API_URL, `${this.prefix}/avatar`)).toString();
        const response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200) {
            return response.data.url;
        }

        throw new Error('Failed to upload avatar');
    }

    validateImageFile(file: File): boolean {
        const validExtensions = ['image/jpeg', 'image/png'];
        const maxSizeMB = 5;
        const isValidExtension = validExtensions.includes(file.type);
        const isValidSize = file.size / 1024 / 1024 <= maxSizeMB;

        return isValidExtension && isValidSize;
    }

    async resizeImage(file: File): Promise<File> {
        const pica = require('pica')();
        const img = await this.loadImage(file);
        const canvas = document.createElement('canvas');
        canvas.width = 256;
        canvas.height = 256;

        await pica.resize(img, canvas);
        const blob = await pica.toBlob(canvas, file.type, 0.9);

        return new File([blob], file.name, { type: file.type });
    }

    loadImage(file: File): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = event?.target?.result as string;
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }
}

const profileService = new ProfileService();
export default profileService;
