import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { useReportWidgetContext } from './ReportWidgetContext';
import { ReportStatus } from 'src/@types/enums';
import { RecordNotification } from 'src/@types/websocket/notifications';
import { Language } from 'src/services/enums';

type State<T> = [T, React.Dispatch<React.SetStateAction<T>>];

export interface ReportSetupState {
    language?: Language | null;
    participant_count?: number | null;
    respondent_known_names: string[];
    goals?: string | null;
    report_language?: string | null;
    status?: ReportStatus | null;
    event?: RecordNotification | null;
}

interface ReportSetupContext {
    reportState: ReportSetupState;
    setReportState: React.Dispatch<React.SetStateAction<ReportSetupState>>;
    isWithTranscriptState: State<boolean>;
    isWithProfileState: State<boolean>;
    isWithJTBDState: State<boolean>;
    isWithInsightsState: State<boolean>;
    isWithRecommendationsState: State<boolean>;
}

const ReportWithSetupContext = createContext<ReportSetupContext | undefined>(undefined);

export const ReportWithSetupProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { report } = useReportWidgetContext();
    const isWithTranscriptState = useState<boolean>(true);
    const isWithProfileState = useState<boolean>(true);
    const isWithJTBDState = useState<boolean>(true);
    const isWithInsightsState = useState<boolean>(true);
    const isWithRecommendationsState = useState<boolean>(true);

    const [, setIsWithInsights] = isWithInsightsState;
    const [, setIsWithJTBD] = isWithJTBDState;
    const [, setIsWithProfile] = isWithProfileState;
    const [, setIsWithRecommendations] = isWithRecommendationsState;
    const [, setIsWithTranscript] = isWithTranscriptState;

    const [reportState, setReportState] = useState<ReportSetupState>({
        language: null,
        participant_count: null,
        respondent_known_names: [],
        goals: null,
        report_language: null,
        status: null,
        event: null,
    });

    useEffect(() => {
        const reportSetup = report?.setup;
        if (report?.status === ReportStatus.InProgress) {
            return;
        }
        if (reportSetup) {
            setIsWithInsights(reportSetup['is_with_insights']);
            setIsWithJTBD(reportSetup['is_with_jtbd']);
            setIsWithProfile(reportSetup['is_with_profile']);
            setIsWithRecommendations(reportSetup['is_with_recommendations']);
            setIsWithTranscript(reportSetup['is_with_transcript']);
            setReportState(prevState => ({
                ...prevState,
                ...reportSetup,
            }));
        } else {
            setIsWithInsights(true);
            setIsWithJTBD(true);
            setIsWithProfile(true);
            setIsWithRecommendations(true);
            setIsWithTranscript(true);
        }
    }, [
        report?.setup,
        report?.status,
        report?.transcripts.length,
        setIsWithInsights,
        setIsWithJTBD,
        setIsWithProfile,
        setIsWithRecommendations,
        setIsWithTranscript,
    ]);

    const initialContext = {
        reportState,
        setReportState,
        isWithTranscriptState,
        isWithProfileState,
        isWithJTBDState,
        isWithInsightsState,
        isWithRecommendationsState,
    };

    return <ReportWithSetupContext.Provider value={initialContext}>{children}</ReportWithSetupContext.Provider>;
};

export const useReportSetupContext = () => {
    const context = useContext(ReportWithSetupContext);
    if (context === undefined) {
        throw new Error('useReportSetupContext must be used within a ReportWithSetupContext');
    }
    return context;
};

export const useIsSetupContextAvailable = () => {
    const context = useContext(ReportWithSetupContext);
    return Boolean(context);
};
