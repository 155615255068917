import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowUpOutlined } from '@ant-design/icons';
import { Button, Dropdown, Flex, Space, Typography, message, theme } from 'antd';

import { ReactComponent as MenuIconAbout } from 'src/assets/images/menu/menu-icon-about.svg';
import { ReactComponent as MenuIconFeedback } from 'src/assets/images/menu/menu-icon-feedback.svg';
import { ReactComponent as MenuIconLogout } from 'src/assets/images/menu/menu-icon-logout.svg';
import { ReactComponent as MenuIconTokens } from 'src/assets/images/menu/menu-icon-tokens.svg';

import { AseedText } from '../AseedTypography/AseedText/AseedText';
import { MenuProfileDivider } from './renders/Dividers';
import { MenuButton } from './renders/MenuButton';
import { MenuCard } from './renders/MenuCard';
import { ProfileResponse } from 'src/@types/profile';
import defaultAvatar from 'src/assets/images/aseed_profile.png';
import { PATH } from 'src/constants/paths';
import profileService from 'src/services/profile.service';

import styles from './Menu.module.scss';

const { Text } = Typography;

const AseedMenu: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState<ProfileResponse>();

    const {
        token: { colorPrimary, colorText },
    } = theme.useToken();

    const avatarSrc = profile?.avatarUrl || defaultAvatar;

    const fetchProfile = async () => {
        setLoading(true);
        profileService
            .getClientProfile()
            .then(profileData => {
                setProfile(profileData);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    const handleSignOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        navigate(PATH.HOME);
    };

    const handleClaimTokens = async () => {
        const result = await profileService.claimTokens();
        if (result) {
            message.success('Tokens claimed successfully');
        } else {
            message.warning('Tokens already claimed');
        }
    };

    const availableTokens = profile?.limits?.totalLimit ? Math.round((profile.limits.totalLimit - profile.limits.currentAmount) * 100) : 0;

    const subMenu = () => {
        return (
            <MenuCard style={{ padding: '16px 12px', boxShadow: '5px 5px 5px 2px #b1b3bb' }}>
                <Flex vertical={true} align="flex-start" gap={12}>
                    {/* <MenuButton text="Claim Tokens" icon={} onClick={handleClaimTokens} /> */}
                    <Flex gap={12} justify="space-between" style={{ width: '100%' }}>
                        <Button
                            type="text"
                            onClick={handleClaimTokens}
                            style={{ fontWeight: 500, fontSize: 18, color: colorText, padding: 5 }}
                        >
                            <Flex align="center" gap={12}>
                                <MenuIconTokens fill={colorText} />
                                <AseedText>Claim Tokens</AseedText>
                            </Flex>
                        </Button>
                        <Text
                            type="secondary"
                            style={{
                                backgroundColor: `${colorPrimary}20`,
                                color: colorPrimary,
                                padding: '4px 12px',
                                borderRadius: '9px',
                                fontWeight: 500,
                            }}
                        >
                            {availableTokens} tokens
                        </Text>
                    </Flex>
                    <MenuProfileDivider />

                    <Flex justify="space-between" style={{ width: '100%' }}>
                        <MenuButton text="Feedback" icon={<MenuIconFeedback fill={colorText} />} onClick={() => {}} />
                        <ArrowUpOutlined style={{ transform: 'rotate(45deg)' }} />
                    </Flex>
                    <MenuButton text="About" icon={<MenuIconAbout fill={colorText} />} onClick={() => {}} />
                    <MenuProfileDivider />
                    <MenuButton text="Sign Out" icon={<MenuIconLogout fill={colorText} />} onClick={handleSignOut} />
                </Flex>
            </MenuCard>
        );
    };

    return (
        <Dropdown trigger={['click']} dropdownRender={() => subMenu()}>
            <img className={userAvatar} src={avatarSrc} alt="" />
        </Dropdown>
    );
};

export default AseedMenu;

const { userAvatar } = styles;
