import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout } from 'antd';

import { AppHeader } from '../components/Header/Header';
import { StartFooter } from '../components/StartFooter';

const { Content } = Layout;

const StartLayout: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Layout style={{ minHeight: '100vh', background: 'linear-gradient(to bottom, #4D4CD0 50%, #E4E7EF 50%)' }}>
            <AppHeader isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            <Content
                style={{
                    background: '#E4E7EF',
                    borderTopRightRadius: 30,
                    borderTopLeftRadius: 30,
                    border: '4px solid #9c99df',
                    borderBottom: 'none',
                }}
            >
                <Outlet />
            </Content>
            <StartFooter setIsModalOpen={setIsModalOpen} />
        </Layout>
    );
};

export default StartLayout;
