import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { notification } from 'antd';

import { provideNotification } from './helpers/notification-providers';
import config from 'src/config';
import authService from 'src/services/auth.service';
import { concatUrl } from 'src/services/helpers/url-helper';

const { CUSTDEV_WS_URL } = config;

type ReportNotificationContext = {
    socketEvent?: MessageEvent;
};

const ReportNotificationCtx = createContext<ReportNotificationContext>({});

export const ReportNotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [api, contextHolder] = notification.useNotification();
    const [socketEvent, setSocketEvent] = useState<MessageEvent>();
    const [reconnectTimerId, setReconnectTimerId] = useState<number | null>(null);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const maxReconnectAttempts = 5;

    const wsUrl = getWebSocketUrl();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            console.error('Token is missing. Unable to establish WebSocket connection.');
            return;
        }
        const connection = createWSConnection(wsUrl, token);
        const socket = connection.connect();

        socket.onopen = () => {
            console.log('WebSocket connection established');
            if (reconnectTimerId) {
                clearTimeout(reconnectTimerId);
                setReconnectTimerId(null);
            }
        };

        socket.onmessage = (event: MessageEvent) => {
            provideNotification(JSON.parse(event.data), api);
            setSocketEvent(event);
        };

        socket.onerror = (error: Event) => {
            console.error('WebSocket error:', error);
        };

        socket.onclose = async event => {
            if (event.code === 403) {
                console.log('Token is invalid. Attempting to refresh...');
                const refreshToken = localStorage.getItem('refreshToken');
                if (refreshToken) {
                    const newToken = await authService.refresh(refreshToken);
                    if (newToken) {
                        console.log('Token refreshed. Reconnecting...');
                        connection.connect();
                    } else {
                        localStorage.removeItem('token');
                        localStorage.removeItem('refreshToken');
                        console.error('Failed to refresh token. Redirecting to login page.');
                        window.location.href = '/';
                    }
                }
            } else if (reconnectAttempts < maxReconnectAttempts) {
                console.log('Connection closed. Attempting to reconnect...');
                setTimeout(() => connection.connect(), 5000);
                setReconnectAttempts(prevAttempts => prevAttempts + 1);
            } else {
                console.error('Reconnection attempts limit reached.');
            }
        };

        return () => {
            if (reconnectTimerId) {
                clearTimeout(reconnectTimerId);
                setReconnectTimerId(null);
            }
            connection.disconnect();
        };
    }, []);

    return (
        <ReportNotificationCtx.Provider value={{ socketEvent }}>
            {contextHolder}
            {children}
        </ReportNotificationCtx.Provider>
    );
};

export const useReportNotificationContext = (): ReportNotificationContext => {
    const context = useContext(ReportNotificationCtx);
    if (context === undefined) {
        throw new Error('useReportNotificationContext must be used within a ReportNotificationProvider');
    }
    return context;
};

function createWSConnection(wsUrl: string, token: string) {
    const socket = new WebSocket(`${wsUrl}?token=${token}`);

    return {
        connect(): WebSocket {
            return socket;
        },

        disconnect(): void {
            socket.close();
        },
    };
}

function getWebSocketUrl(): string {
    return new URL(concatUrl(CUSTDEV_WS_URL, '/notifications')).toString();
}
