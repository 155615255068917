import { DetailedReportResponse } from '../../../@types/jtbd';
import { InsightsFormatter } from './InsightsFormatter';
import { JTBDFormatter } from './JTBDFormatter';
import { ProfileFormatter } from './ProfileFormatter';
import { RecommendationsFormatter } from './RecommendationsFormatter';

export class InsightReportFormatter {
    private profileFormatter = new ProfileFormatter();
    private insightsFormatter = new InsightsFormatter();
    private recommendationsFormatter = new RecommendationsFormatter();
    private jtbdFormatter = new JTBDFormatter();

    public formatInsightReport(report: DetailedReportResponse): string {
        const sections: string[] = [];

        if (report.profile) {
            sections.push(this.profileFormatter.formatProfile(report.profile));
        }
        if (report.insights) {
            sections.push(this.insightsFormatter.formatInsights(report.insights));
        }
        if (report.recommendations) {
            sections.push(this.recommendationsFormatter.formatRecommendations(report.recommendations));
        }
        if (report.jtbd) {
            sections.push(this.jtbdFormatter.formatJTBD(report.jtbd));
        }

        return sections.join('\n\n');
    }
}

export const insightReportFormatter = new InsightReportFormatter();
