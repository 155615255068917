import React from 'react';

import { Flex, theme } from 'antd';

import { AseedText } from '../AseedTypography/AseedText/AseedText';

interface MessageBubbleProps {
    isRespondent: boolean;
    knownName: string;
    text: string;
}

const MessageBubble: React.FC<MessageBubbleProps> = ({ isRespondent, knownName, text }) => {
    const {
        token: { colorPrimary },
    } = theme.useToken();

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: isRespondent ? 'flex-end' : 'flex-start',
                marginBottom: '10px',
            }}
        >
            <div
                style={{
                    maxWidth: '70%',
                    padding: '12px',
                    borderRadius: '12px',
                    borderBottomLeftRadius: isRespondent ? '12px' : '0',
                    borderBottomRightRadius: isRespondent ? '0' : '12px',
                    backgroundColor: isRespondent ? `${colorPrimary}25` : '#E5E5EA70',
                }}
            >
                <Flex vertical gap={12}>
                    <AseedText active>{knownName}</AseedText>
                    <AseedText description>{text}</AseedText>
                </Flex>
            </div>
        </div>
    );
};

export default MessageBubble;
