import { AuthSignInResponse, AuthSignUpResponse, RestoreResponse } from '../@types/auth';
import config from '../config';
import { firebaseAuth } from './firebase/AseedFirebase';
import { concatUrl } from './helpers/url-helper';
import axios from 'axios';
import { signInWithEmailAndPassword } from 'firebase/auth';

const { ASEED_API_URL } = config;

class AuthService {
    prefix = '/api/v1/auth';

    async signUp(fullname: string, email: string, password: string): Promise<AuthSignUpResponse> {
        const url = new URL(concatUrl(ASEED_API_URL, `${this.prefix}/sign-up`)).toString();
        const response = await axios.post(
            url,
            { fullname, email, password },
            {
                headers: {
                    Authorization: '',
                },
            }
        );
        if (response.status === 200) {
            return response.data;
        }
        throw new Error('Sign-up failed');
    }

    async signIn(email: string, password: string): Promise<AuthSignInResponse> {
        const url = new URL(concatUrl(ASEED_API_URL, `${this.prefix}/sign-in`)).toString();
        const response = await axios.post(
            url,
            { email, password },
            {
                headers: {
                    Authorization: '',
                },
            }
        );

        if (response.status === 200) {
            const { token, refreshToken } = response.data;
            if (token && refreshToken) {
                localStorage.setItem('token', token);
                localStorage.setItem('refreshToken', refreshToken);

                await signInWithEmailAndPassword(firebaseAuth, email, password);
            }
            return response.data;
        }
        throw new Error('Sign-in failed');
    }

    async refresh(refreshToken: string): Promise<AuthSignInResponse> {
        const url = new URL(concatUrl(ASEED_API_URL, `${this.prefix}/refresh`)).toString();
        const response = await axios.post(
            url,
            { refreshToken },
            {
                headers: {
                    Authorization: '',
                },
            }
        );
        if (response.status === 200) {
            const { token, refreshToken: newRefreshToken } = response.data;
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', newRefreshToken);

            const currentUser = firebaseAuth.currentUser;
            if (currentUser) {
                await currentUser.getIdToken(true);
            }
            return response.data;
        }
        throw new Error('Token refresh failed');
    }

    async restore(email: string): Promise<RestoreResponse> {
        const url = new URL(concatUrl(ASEED_API_URL, `${this.prefix}/restore`)).toString();
        const response = await axios.post(
            url,
            { email },
            {
                headers: {
                    Authorization: '',
                },
            }
        );
        if (response.status === 200) {
            return response.data;
        }
        throw new Error('Password restore failed');
    }
}

const authService = new AuthService();

axios.interceptors.request.use(
    async config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

axios.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error && error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
                try {
                    const refreshed = await authService.refresh(refreshToken);
                    localStorage.setItem('token', refreshed.token!);
                    localStorage.setItem('refreshToken', refreshed.refreshToken!);
                    const currentUser = firebaseAuth.currentUser;
                    if (currentUser) {
                        await currentUser.getIdToken(true);
                    }
                    axios.defaults.headers.common['Authorization'] = `Bearer ${refreshed.token!}`;
                    return axios(originalRequest);
                } catch (refreshError) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    window.location.href = '/';
                }
            }
        }
        return Promise.reject(error);
    }
);

export default authService;
