import { Language } from 'src/@types/enums';

const availableLanguages = [Language.English, Language.Russian, Language.Spanish, Language.French, Language.German];

export const AUTOMATIC_DETECTION = 'Automatic Detection' as const;
export const SAME_AS_TRANSCRIPT = 'Same as Transcript' as const;

export const LANGUAGE_OPTIONS = {
    AUTOMATIC_DETECTION,
    SAME_AS_TRANSCRIPT,
} as const;

export const createLanguageOptions = (defaultOption: string) => [
    { value: defaultOption, label: defaultOption },
    ...availableLanguages.map(lang => ({ value: lang, label: lang })),
];

export interface ParticipantOption {
    value: string;
    label: string;
}

export const createParticipantOptions = (maxParticipants: number): ParticipantOption[] => {
    const options: ParticipantOption[] = [{ value: 'Automatic Detection', label: 'Automatic Detection' }];

    for (let i = 2; i <= maxParticipants; i++) {
        options.push({ value: i.toString(), label: i.toString() });
    }

    return options;
};
