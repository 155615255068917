import React from 'react';

import ReportWidget from 'src/components/Report/ReportWidget/ReportWidget';
import { ReportWidgetIntroProvider } from 'src/components/Report/ReportWidget/ReportWidgetContext';

export const Home: React.FC = () => {
    return (
        <ReportWidgetIntroProvider>
            <ReportWidget />
        </ReportWidgetIntroProvider>
    );
};
