import React, { useState } from 'react';

import { Button, Collapse, Flex, Space, message, theme } from 'antd';

import { AseedText } from 'src/components/AseedTypography/AseedText/AseedText';
import { SourcesTooltip } from 'src/components/AseedTypography/AseedText/SourcesTooltip';
import { AseedTitle } from 'src/components/AseedTypography/AseedTitle/AseedTitle';
import { useReportTabsContext } from 'src/components/Report/ReportWidget/ReportTabController/ReportTabsContext';

import CopyDescriptionToBuffer from '../helpers/DescriptionToText';
import { ExampledDescription, GeneralProfileInfo, ScoredDescription, SolutionsDescription } from 'src/@types/report';

const { Panel } = Collapse;

export type DescriptionItems = ExampledDescription[] | SolutionsDescription[] | ScoredDescription[] | GeneralProfileInfo;

const DescriptionSection: React.FC<{
    id: string;
    title: string;
    children: React.ReactNode;
    items: DescriptionItems;
}> = ({ id, title, children, items }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const { expandedKeysState } = useReportTabsContext();
    const [expandedKeys, setExpandedKeys] = expandedKeysState;

    const onPanelChange = (keys: string | string[]) => {
        setExpandedKeys(Array.isArray(keys) ? keys : [keys]);
    };

    const {
        token: { colorPrimary },
    } = theme.useToken();

    const handleCopy = () => {
        CopyDescriptionToBuffer(title, items);
        messageApi.success('Copied to buffer');
    };

    const toggleExpand = () => {
        const sectionKeys = Array.isArray(items) ? items.map((_, index) => `${id}-${index}`) : [];
        const newExpandedKeys = expandedKeys.includes(sectionKeys[0])
            ? expandedKeys.filter(key => !sectionKeys.includes(key))
            : [...expandedKeys, ...sectionKeys];
        onPanelChange(newExpandedKeys);
    };

    const isExpanded = Array.isArray(items) && items.some((_, index) => expandedKeys.includes(`${id}-${index}`));

    return (
        <div
            id={id}
            style={{
                borderRadius: '16px',
                padding: '16px',
                margin: '0',
                transition: 'all 0.3s ease',
                backgroundColor: isHovered ? `${colorPrimary}10` : 'transparent',
            }}
            onMouseEnter={_ => {
                setIsHovered(true);
            }}
            onMouseLeave={_ => {
                setIsHovered(false);
            }}
        >
            {contextHolder}
            <Space direction="vertical" size={12} style={{ width: '100%' }}>
                <Flex justify="space-between" align="center" style={{ margin: 0, padding: 0 }}>
                    <AseedTitle>{title}</AseedTitle>
                    <Space>
                        <Button
                            icon={isExpanded ? '􀁰' : '􀁮'}
                            onClick={toggleExpand}
                            type="text"
                            style={{
                                opacity: isHovered ? 1 : 0,
                                transition: 'opacity 0.3s ease',
                            }}
                        />
                        <Button
                            icon={'􀐅'}
                            onClick={handleCopy}
                            type="text"
                            style={{
                                opacity: isHovered ? 1 : 0,
                                transition: 'opacity 0.3s ease',
                            }}
                        />
                    </Space>
                </Flex>
                {children}
            </Space>
        </div>
    );
};

interface ReportDescriptionSectionProps {
    id: string;
    title: string;
    items: ExampledDescription[] | SolutionsDescription[] | ScoredDescription[] | GeneralProfileInfo | undefined;
    iconSymbol: string;
}

export const ReportDescriptionSection: React.FC<ReportDescriptionSectionProps> = ({ id, title, items, iconSymbol }) => {
    const { expandedKeysState } = useReportTabsContext();
    const [expandedKeys, setExpandedKeys] = expandedKeysState;

    const onPanelChange = (keys: string | string[]) => {
        setExpandedKeys(Array.isArray(keys) ? keys : [keys]);
    };

    if (!items) return null;

    if ('name' in items) {
        return (
            <Flex
                vertical
                id={id}
                style={{
                    borderRadius: '16px',
                    padding: '16px',
                    margin: '0',
                    border: '1px solid #ddd',
                }}
                gap={16}
            >
                <AseedTitle>{title}</AseedTitle>
                <Flex vertical gap={12} style={{ padding: '0 16px' }}>
                    <AseedText>
                        <strong>Name:</strong> {items.name}
                    </AseedText>
                    <AseedText>
                        <strong>Age:</strong> {items.age}
                    </AseedText>
                    <AseedText>
                        <strong>Professional experience:</strong> {items.professional_experience}
                    </AseedText>
                    <AseedText>
                        <strong>Current role:</strong> {items.current_role}
                    </AseedText>
                    <AseedText>
                        <strong>Description:</strong> {items.description}
                    </AseedText>
                </Flex>
            </Flex>
        );
    }

    const genTextTitle = (text: string, active?: boolean) => <AseedText active={active}>{text}</AseedText>;

    return (
        <DescriptionSection id={id} title={title} items={items}>
            <Collapse
                activeKey={expandedKeys}
                onChange={onPanelChange}
                ghost
                expandIcon={({ isActive }) => (
                    <AseedText active={isActive}>
                        <div style={{ width: '18px' }}>{iconSymbol}</div>
                    </AseedText>
                )}
            >
                {Array.isArray(items)
                    ? items.map((item, index) => (
                          <Panel header={genTextTitle(item.title, expandedKeys?.includes(`${id}-${index}`))} key={`${id}-${index}`}>
                              {'text' in item && <AseedText description>{item.text}</AseedText>}
                              {'solutions' in item && (
                                  <Flex vertical gap={6}>
                                      {item.solutions.map((solution, solIndex) => (
                                          <div key={`${id}-${index}-${solIndex}`}>
                                              <AseedText description>
                                                  • {solution.prefix_text && <strong>{solution.prefix_text}:</strong>} {solution.text}
                                              </AseedText>
                                          </div>
                                      ))}
                                  </Flex>
                              )}
                              {'dialog_examples' in item && <SourcesTooltip dialogExamples={item.dialog_examples} />}
                          </Panel>
                      ))
                    : null}
            </Collapse>
        </DescriptionSection>
    );
};
