import { GeneralProfileInfo, Profile, ScoredDescription } from 'src/@types/report';

export class ProfileFormatter {
    private formatDescription(description: ScoredDescription): string {
        return `- **${description.title}:** ${description.text}${description.score !== undefined ? ` (Score: ${description.score})` : ''}`;
    }

    public formatProfile(profile: Profile): string {
        const generalInfo: GeneralProfileInfo = profile.general_info;
        const sections: string[] = [
            '### Profile',
            `**Name**: ${generalInfo.name || 'N/A'}`,
            `**Age**: ${generalInfo.age || 'N/A'}`,
            `**Professional Experience**: ${generalInfo.professional_experience || 'N/A'}`,
            `**Current Role**: ${generalInfo.current_role || 'N/A'}`,
            `**Description**: ${generalInfo.description || 'N/A'}`,
            '\n#### Goals',
            profile.goals.map(this.formatDescription).join('\n'),
            '\n#### Motivations',
            profile.motivations.map(this.formatDescription).join('\n'),
            '\n#### Pain Points',
            profile.pain_points.map(this.formatDescription).join('\n'),
            '\n#### Emotions',
            profile.emotions.map(this.formatDescription).join('\n'),
            '\n#### Tools',
            profile.tools.map(this.formatDescription).join('\n'),
        ];

        return sections.join('\n');
    }
}
