import config from '../config';
import { concatUrl } from './helpers/url-helper';
import axios from 'axios';
import { DetailedReportResponse } from 'src/@types/jtbd';

const { CUSTDEV_API_URL } = config;

class ReportService {
    async getIntroReport(): Promise<DetailedReportResponse> {
        const url = new URL(concatUrl(CUSTDEV_API_URL, '/jtbd/intro')).toString();
        const response = await axios.get(url, {
            headers: {
                Authorization: '',
            },
        });
        if (response.status === 200) {
            return response.data;
        }
        throw new Error('Failed to fetch intro report');
    }
}

const reportService = new ReportService();
export default reportService;
