import { Recommendations, Solution, SolutionsDescription } from 'src/@types/report';

export class RecommendationsFormatter {
    private formatSolutionsDescription(solutionDesc: SolutionsDescription): string {
        const solutionsText = solutionDesc.solutions.map((sol: Solution) => `  - ${sol.text}`).join('\n');
        return `- **${solutionDesc.title}:**\n${solutionsText}`;
    }

    public formatRecommendations(recommendations: Recommendations): string {
        const sections: string[] = [
            '### Recommendations',
            '\n#### Actionable Steps',
            recommendations.actionable_steps.map(this.formatSolutionsDescription).join('\n'),
            '\n#### Product Improvements',
            recommendations.product_improvements.map(this.formatSolutionsDescription).join('\n'),
            '\n#### Service Enhancements',
            recommendations.service_enhancements.map(this.formatSolutionsDescription).join('\n'),
        ];

        return sections.join('\n');
    }
}
