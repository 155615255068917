import { Divider, Flex } from 'antd';

export const MenuDivider: React.FC = () => (
    <Divider
        style={{
            height: '1px',
            margin: '4px 0',
            backgroundColor: '#3E3E4266',
        }}
    />
);

export const MenuProfileDivider: React.FC = () => (
    <Flex style={{ padding: '0 5px', width: '100%' }}>
        <Divider
            style={{
                height: '1px',
                margin: '4px 0',
                backgroundColor: '#3E3E4266',
            }}
        />
    </Flex>
);
