import { Transcript } from 'src/@types/report';

export class TranscriptFormatter {
    private formatTranscript(transcript: Transcript): string {
        return `**${transcript.known_name}:**\n${transcript.text}`;
    }

    public formatTranscripts(transcripts: Transcript[]): string {
        const sections: string[] = ['### Transcripts\n', transcripts.map(this.formatTranscript).join('\n\n')];

        return sections.join('\n');
    }
}

export const transcriptFormatter = new TranscriptFormatter();
