import { Button, Flex, theme } from 'antd';

import { AseedText } from 'src/components/AseedTypography/AseedText/AseedText';

interface MenuButtonProps {
    text: string;
    icon: React.ReactNode;
    onClick?: () => void;
}

export const MenuButton: React.FC<MenuButtonProps> = ({ text, icon, onClick }) => {
    const {
        token: { colorText },
    } = theme.useToken();

    return (
        <Button type="text" onClick={onClick} style={{ fontWeight: 500, fontSize: 18, color: colorText, padding: 5 }}>
            <Flex align="center" gap={12}>
                {icon}
                <AseedText>{text}</AseedText>
            </Flex>
        </Button>
    );
};
