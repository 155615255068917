import config from '../../config';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const { FIREBASE_CONFIG } = config;

const app = initializeApp(FIREBASE_CONFIG);
export const firebaseStorage = getStorage(app);
export const firebaseAuth = getAuth(app);
