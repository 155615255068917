import { Flex } from 'antd';

import MessageBubble from '../../../../MessageBubble';
import { Transcript } from 'src/@types/report';

interface ReportTranscriptProps {
    transcripts: Transcript[];
}

export const ReportTranscript: React.FC<ReportTranscriptProps> = ({ transcripts }) => (
    <Flex gap={12} vertical style={{ padding: '0 90px' }}>
        <div style={{ maxHeight: '1800px', overflowY: 'auto', padding: '20px' }}>
            {transcripts?.map(message => (
                <MessageBubble key={message.id} isRespondent={message.is_respondent} knownName={message.known_name} text={message.text} />
            ))}
        </div>
    </Flex>
);
