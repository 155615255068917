import { ReactNode, createContext, useContext, useState } from 'react';

type State<T> = [T, React.Dispatch<React.SetStateAction<T>>];

type ReportTabsContext = {
    hoveredLinkState: State<string | null>;
    expandedKeysState: State<string[]>;
};

const ReportTabsCtx = createContext<ReportTabsContext | undefined>(undefined);

export const ReportTabsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const hoveredLinkState = useState<string | null>(null);
    const expandedKeysState = useState<string[]>([]);

    const initialContext: ReportTabsContext = {
        hoveredLinkState,
        expandedKeysState,
    };

    return <ReportTabsCtx.Provider value={initialContext}>{children}</ReportTabsCtx.Provider>;
};

export const useReportTabsContext = (): ReportTabsContext => {
    const context = useContext(ReportTabsCtx);
    if (context === undefined) {
        throw new Error('useReportTabsContext must be used within a ReporTabsProvider');
    }
    return context;
};
