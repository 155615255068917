import React from 'react';

import { Flex, Space } from 'antd';

import { AnchorLinkParams, AnchorLinks } from '../../../../AnchorLinks/AnchorLinks';
import { ReportDescriptionSection } from '../../../../renders/RenderDescriptionSection';
import { Insights } from 'src/@types/report';

interface ReportInsightsProps {
    insights: Insights;
}

export const ReportInsights: React.FC<ReportInsightsProps> = ({ insights }) => {
    const links = getAnchorLinks(insights);
    const sections = getSections(insights);

    return (
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <AnchorLinks links={links} />
            <Flex gap={12} vertical style={{ padding: '0 90px' }}>
                {sections.map(({ id, title, items, iconSymbol }) => (
                    <ReportDescriptionSection key={id} id={id} title={title} items={items} iconSymbol={iconSymbol} />
                ))}
            </Flex>
        </Space>
    );
};

const getAnchorLinks = (insights: Insights): AnchorLinkParams[] => {
    return [
        {
            href: '#key-insights',
            title: 'Key Insights',
            iconSymbol: '􀉿',
            count: insights.key_insights.length,
        },
        {
            href: '#barriers',
            title: 'Barriers',
            iconSymbol: '􂤁',
            count: insights.barriers.length,
        },
        {
            href: '#engagements',
            title: 'Engagements',
            iconSymbol: '􀟮',
            count: insights.engagements.length,
        },
        {
            href: '#expectations',
            title: 'Expectations',
            iconSymbol: '􀙿',
            count: insights.expectations.length,
        },
    ];
};

const getSections = (insights: Insights) => {
    return [
        {
            id: 'key-insights',
            title: 'Key Insights',
            items: insights.key_insights,
            iconSymbol: '􀉿',
        },
        {
            id: 'barriers',
            title: 'Barriers',
            items: insights.barriers,
            iconSymbol: '􂤁',
        },
        {
            id: 'engagements',
            title: 'Engagements',
            items: insights.engagements,
            iconSymbol: '􀟮',
        },
        {
            id: 'expectations',
            title: 'Expectations',
            items: insights.expectations,
            iconSymbol: '􀙿',
        },
    ];
};
