import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import config from '../config';

const { IS_SENTRY_ENABLED, SENTRY_DSN, TRACE_TARGETS } = config;

export function initSentry() {
    if (IS_SENTRY_ENABLED){
        console.log("Sentry enabled")
        Sentry.init({
            dsn: SENTRY_DSN,
            integrations: [
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
                Sentry.replayIntegration(),
            ],
            tracesSampleRate: 1.0,
            tracePropagationTargets: TRACE_TARGETS,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    }
}
