import React from 'react';

import { Flex, Space } from 'antd';

import { AnchorLinkParams, AnchorLinks } from '../../../../AnchorLinks/AnchorLinks';
import { ReportDescriptionSection } from '../../../../renders/RenderDescriptionSection';
import { Recommendations } from 'src/@types/report';

interface ReportRecommendationsProps {
    recommendations: Recommendations;
}

export const ReportRecommendations: React.FC<ReportRecommendationsProps> = ({ recommendations }) => {
    const links = getAnchorLinks(recommendations);
    const sections = getSections(recommendations);

    return (
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <AnchorLinks links={links} />
            <Flex gap={12} vertical style={{ padding: '0 90px' }}>
                {sections.map(({ id, title, items, iconSymbol }) => (
                    <ReportDescriptionSection key={id} id={id} title={title} items={items} iconSymbol={iconSymbol} />
                ))}
            </Flex>
        </Space>
    );
};

const getAnchorLinks = (recommendations: Recommendations): AnchorLinkParams[] => {
    return [
        {
            href: '#actionable-steps',
            title: 'Actionable Steps',
            iconSymbol: '􂨪',
            count: recommendations.actionable_steps.length,
        },
        {
            href: '#product-improvements',
            title: 'Product Improvements',
            iconSymbol: '􂨪',
            count: recommendations.product_improvements.length,
        },
        {
            href: '#service-enhancements',
            title: 'Service Enhancements',
            iconSymbol: '􂨪',
            count: recommendations.service_enhancements.length,
        },
    ];
};

const getSections = (recommendations: Recommendations) => {
    return [
        {
            id: 'actionable-steps',
            title: 'Actionable Steps',
            items: recommendations.actionable_steps,
            iconSymbol: '􂨪',
        },
        {
            id: 'product-improvements',
            title: 'Product Improvements',
            items: recommendations.product_improvements,
            iconSymbol: '􂨪',
        },
        {
            id: 'service-enhancements',
            title: 'Service Enhancements',
            items: recommendations.service_enhancements,
            iconSymbol: '􂨪',
        },
    ];
};
