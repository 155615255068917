const parseTraceTargets = (targetsString: string): RegExp[] => {
    try {
        const patterns = JSON.parse(targetsString);
        return patterns.map((pattern: string) => new RegExp(pattern));
    } catch (error) {
        console.error('Failed to parse TRACE_TARGETS:', error);
        return [/^\//];
    }
};

const config = {
    ASEED_API_URL: process.env.REACT_APP_ASEED_API_URL!,
    CUSTDEV_API_URL: process.env.REACT_APP_CUSTDEV_API_URL!,
    CUSTDEV_WS_URL: process.env.REACT_APP_CUSTDEV_WS_URL!,
    FIREBASE_CONFIG: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!),
    IS_SENTRY_ENABLED: process.env.REACT_APP_IS_SENTRY_ENABLED === 'true',
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN!,
    TRACE_TARGETS: parseTraceTargets(process.env.REACT_APP_TRACE_TARGETS!),
};

export default config;
